'use client';

import { useEffect } from 'react';

import { Button } from '@ui/components/ui/button';

import Header from '@/components/header';

export default function Error({ error, reset }: { error: Error & { digest?: string }; reset: () => void }) {
	useEffect(() => {
		// Log the error to an error reporting service
		console.error('An error occured: ', error.message);
	}, [error]);

	return (
		<>
			<Header variant='public' />

			<div className='min-h-screen-ios mx-auto flex min-h-[calc(100vh_-_theme(spacing.16))] max-w-lg flex-col items-center justify-center gap-4'>
				<h1 className='text-3xl'>Oeps, er is helaas iets mis gegaan.</h1>
				<Button
					variant='default'
					onClick={
						// Attempt to recover by trying to re-render the segment
						() => reset()
					}>
					Probeer opnieuw
				</Button>
			</div>
		</>
	);
}
